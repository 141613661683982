// this file controls sentry error reporting on the client (browser)

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"6f33d74073"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs';
import { logger } from '@kamernet/core/Logger';
import { isAcceptanceOrProduction } from '@kamernet/core/Environment';
import { SENTRY_BASE_CONFIG } from './sentry.base.config';

if (isAcceptanceOrProduction()) {
  logger.info('Sentry Client loaded');
  Sentry.init({
    ...SENTRY_BASE_CONFIG,
  });
}
