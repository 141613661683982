import {
  AUTH_SIGNIN_URL,
  AUTH_SIGNOUT_URL,
  AUTH_SIGNUP_URL,
} from '@ha/auth/common/routes';

import { Locale } from '@kamernet/core/Intl/types';

import { defineRoute } from './defineRoute';
import { MyConversations } from './routes/MyConversations';
import { TenantPublicProfile } from './routes/TenantPublicProfile';
import {
  LCFRouteParams,
  ListingDetailsRouteParams,
  ListingSearchCityRouteParams,
  ListingSearchStreetRouteParams,
} from './types';

export * from './types';

export const ROUTES = Object.freeze({
  Home: defineRoute({ pathname: '/' }),

  // Source for A/B test Contextual IDP message 3 April 2024
  Signin: defineRoute<{
    isUserLandlord?: 'true';
    source?: string;
    prevUrl?: string;
  }>({
    pathname: AUTH_SIGNIN_URL,
  }),
  Signup: defineRoute<{
    isUserLandlord?: 'true';
    source?: string;
    prevUrl?: string;
  }>({
    pathname: AUTH_SIGNUP_URL,
  }),
  Signout: defineRoute({ pathname: AUTH_SIGNOUT_URL }),

  LegacyLogin: defineRoute<{ returnUrl?: string }>({
    pathname: '/user/login',
    nlPathname: '/gebruiker/inloggen',
  }),

  // Search
  Search: defineRoute({
    pathname: '/for-rent/properties-netherlands',
    nlPathname: '/huren/huurwoningen-nederland',
  }),

  SearchTenant: defineRoute({
    pathname: '/tenants/room-netherlands',
    nlPathname: '/huurders/kamer-nederland',
    isLandlordRoute: true,
  }),

  // Profile page
  Dashboard: defineRoute({ pathname: '/dashboard' }),

  MyConversations,

  MyMessagesByConverationId: defineRoute<{ conversationId: string }>({
    pathname: '/my-messages/all/conversation/[conversationId]',
    nlPathname: '/mijn-berichten/all/conversaties/[conversationId]',
  }),

  MyAccount: defineRoute({
    pathname: '/my-account',
    nlPathname: '/mijn-account',
  }),

  PublicProfile: defineRoute({
    pathname: '/public-profile',
    nlPathname: '/openbaar-profiel',
  }),

  AccountAlerts: defineRoute({ pathname: '/account/alerts' }),

  Settings: defineRoute({
    pathname: '/account/settings',
    nlPathname: '/account/instellingen',
  }),

  ReferFriend: defineRoute({ pathname: '/referrals' }),

  Cashback: defineRoute({ pathname: '/cashback' }),

  // Favorites
  MyFavoriteRooms: defineRoute({
    pathname: '/my-favorites/rooms',
    nlPathname: '/mijn-favorieten/kamers',
  }),

  MyFavoriteTenants: defineRoute({
    pathname: '/my-favorites/tenants',
    nlPathname: '/mijn-favorieten/huurders',
    isLandlordRoute: true,
  }),

  LCFStart: defineRoute({
    pathname: '/create-listing',
    nlPathname: '/maken-advertentie',
    isLandlordRoute: true,
  }),

  LCFContinue: defineRoute<LCFRouteParams>({
    pathname: '/create-listing/[listingID]/continue',
    nlPathname: '/maken-advertentie/[listingID]/continue',
    isLandlordRoute: true,
  }),

  LCFListingType: defineRoute<LCFRouteParams>({
    pathname: '/create-listing/[listingID]/listing-type',
    nlPathname: '/maken-advertentie/[listingID]/listing-type',
    isLandlordRoute: true,
  }),

  LCFOwnerType: defineRoute<LCFRouteParams>({
    pathname: '/create-listing/[listingID]/owner-type',
    nlPathname: '/maken-advertentie/[listingID]/owner-type',
    isLandlordRoute: true,
  }),

  LCFStudentHouse: defineRoute<LCFRouteParams>({
    pathname: '/create-listing/[listingID]/student-house',
    nlPathname: '/maken-advertentie/[listingID]/student-house',
    isLandlordRoute: true,
  }),

  LCFAddress: defineRoute<LCFRouteParams>({
    pathname: '/create-listing/[listingID]/address',
    nlPathname: '/maken-advertentie/[listingID]/address',
    isLandlordRoute: true,
  }),

  LCFAvailability: defineRoute<LCFRouteParams>({
    pathname: '/create-listing/[listingID]/availability',
    nlPathname: '/maken-advertentie/[listingID]/availability',
    isLandlordRoute: true,
  }),

  LCFSurfaceFurnishing: defineRoute<LCFRouteParams>({
    pathname: '/create-listing/[listingID]/surface-furnishing',
    nlPathname: '/maken-advertentie/[listingID]/surface-furnishing',
    isLandlordRoute: true,
  }),

  LCFRentalPrice: defineRoute<LCFRouteParams>({
    pathname: '/create-listing/[listingID]/rental-price',
    nlPathname: '/maken-advertentie/[listingID]/rental-price',
    isLandlordRoute: true,
  }),

  LCFMedia: defineRoute<LCFRouteParams>({
    pathname: '/create-listing/[listingID]/media',
    nlPathname: '/maken-advertentie/[listingID]/media',
    isLandlordRoute: true,
  }),

  LCFDetails: defineRoute<LCFRouteParams>({
    pathname: '/create-listing/[listingID]/details',
    nlPathname: '/maken-advertentie/[listingID]/details',
    isLandlordRoute: true,
  }),

  LCFPreferredTenant: defineRoute<LCFRouteParams>({
    pathname: '/create-listing/[listingID]/preferred-tenant',
    nlPathname: '/maken-advertentie/[listingID]/preferred-tenant',
    isLandlordRoute: true,
  }),

  LCFCurrentResidents: defineRoute<LCFRouteParams>({
    pathname: '/create-listing/[listingID]/current-residents',
    nlPathname: '/maken-advertentie/[listingID]/current-residents',
    isLandlordRoute: true,
  }),

  LCFTitleDescription: defineRoute<LCFRouteParams>({
    pathname: '/create-listing/[listingID]/title-description',
    nlPathname: '/maken-advertentie/[listingID]/title-description',
    isLandlordRoute: true,
  }),

  LCFSupplyProducts: defineRoute<LCFRouteParams>({
    pathname: '/create-listing/[listingID]/supply-products',
    nlPathname: '/maken-advertentie/[listingID]/supply-products',
    isLandlordRoute: true,
  }),

  LCFReactivate: defineRoute<LCFRouteParams>({
    pathname: '/create-listing/[listingID]/reactivate',
    nlPathname: '/maken-advertentie/[listingID]/reactivate',
    isLandlordRoute: true,
  }),

  MyListings: defineRoute<{
    pageNo?: string;
    listingStatus?: string;
    searchTerm?: string;
  }>({
    pathname: '/my-adverts',
    nlPathname: '/mijn-advertenties',
    isLandlordRoute: true,
  }),

  RentOutRoom: defineRoute({
    pathname: '/rent-out-room',
    nlPathname: '/kamer-verhuren',
    isLandlordRoute: true,
  }),

  RentOutHome: defineRoute({
    pathname: '/rent-out-home',
    nlPathname: '/woning-verhuren',
    isLandlordRoute: true,
  }),

  StudentHouse: defineRoute({
    pathname: '/studenthouse',
    nlPathname: '/studentenhuis',
    isLandlordRoute: true,
  }),

  SafetyLandlord: defineRoute({
    pathname: '/safety-landlord',
    nlPathname: '/veiligheid-verhuurder',
    isLandlordRoute: true,
  }),

  SafetyTenant: defineRoute({
    pathname: '/safety-tenant',
    nlPathname: '/veiligheid-huurder',
  }),

  // How does it work
  HowDoesItWorkTenant: defineRoute({
    pathname: '/how-does-it-work',
    nlPathname: '/hoe-werkt-het',
  }),
  HowDoesItWorkLandlord: defineRoute({
    pathname: '/landlord/how-does-it-work',
    nlPathname: '/verhuur/hoe-werkt-het',
    isLandlordRoute: true,
  }),

  TermsAndConditions: defineRoute({
    pathname: '/terms-conditions',
    nlPathname: '/algemene-voorwaarden',
  }),
  PrivacyPolicy: defineRoute({
    pathname: '/privacypolicy',
    nlPathname: '/privacyverklaring',
  }),
  CookiePolicy: defineRoute({
    pathname: '/cookie-policy',
    nlPathname: '/cookie-beleid',
  }),
  // Subscription purchase
  PremiumListing: defineRoute({
    pathname: '/premium-account-payment',
    nlPathname: '/premium-account-betaling',
  }),
  MakePayment: defineRoute<{
    paymentStatusSuccessMessage?: string;
    selectedProduct?: string;
    selectedProductRatePlan?: string;
  }>({
    pathname: '/premium-account-payment/make-payment',
    nlPathname: '/premium-account-betaling/betaal',
  }),
  BillingDetails: defineRoute<{
    paymentStatusSuccessMessage?: string;
    selectedProduct?: string;
    selectedProductRatePlan?: string;
    isAfterPay?: string;
  }>({
    pathname: '/premium-account-payment/personaldetails',
    nlPathname: '/premium-account-betaling/persoonsgegevens',
  }),
  PaymentMethod: defineRoute<{
    paymentStatusSuccessMessage?: string;
    selectedProduct?: string;
    selectedProductRatePlan?: string;
  }>({
    pathname: '/premium-account-payment/paymentmethod',
    nlPathname: '/premium-account-betaling/betaalmethode',
  }),

  PaymentReminder: defineRoute<{ paymentIntentId?: string }>({
    pathname: '/payment-reminder',
    nlPathname: '/betalingsherinnering',
  }),

  AboutUs: defineRoute({ pathname: '/about-us', nlPathname: '/over-ons' }),

  PaymentStatus: defineRoute<{
    payment_intent_client_secret?: string;
    paymentStatusSuccessMessage?: string;
    trackAnalytics?: string;
    trackAffiliates?: string;
  }>({ pathname: '/paymentstatus', nlPathname: '/betalingsstatus' }),

  Partnerships: defineRoute({
    pathname: '/samenwerken/adverteren',
    nlPathname: undefined,
    isLandlordRoute: true,
  }),

  LandlordPremium: defineRoute({
    pathname: '/tenant-search/payment-step1',
    nlPathname: '/huurder-zoeken/betaling-stap1',
    isLandlordRoute: true,
  }),

  UnsubscribeEmail: defineRoute({
    pathname: '/unsubscribe',
    nlPathname: '/uitschrijven',
  }),
  RentOk: defineRoute({ pathname: '/rent-ok', nlPathname: '/rent-ok' }),

  ListingSearchCity: defineRoute<ListingSearchCityRouteParams>({
    pathname: '/for-rent/[typeAndCity]',
    nlPathname: '/huren/[typeAndCity]',
  }),

  ListingSearchStreet: defineRoute<ListingSearchStreetRouteParams>({
    pathname: '/for-rent/[typeAndCity]/[street]',
    nlPathname: '/huren/[typeAndCity]/[street]',
  }),

  ListingDetails: defineRoute<ListingDetailsRouteParams>({
    pathname: '/for-rent/[typeAndCity]/[street]/[typeAndListingId]',
    nlPathname: '/huren/[typeAndCity]/[street]/[typeAndListingId]',
  }),

  ContactForm: defineRoute<{ listingId: string }>({
    pathname: '/start-conversation/[listingId]',
    nlPathname: '/start-gesprek/[listingId]',
  }),
  LandlordProfile: defineRoute<{ profileId: string; refid?: string }>({
    pathname: '/offer-a-home/tenant/[profileId]/displayroomadvert',
    nlPathname: '/verhuren/huurder/[profileId]/displayroomadvert',
    isLandlordRoute: true,
  }),

  PreviewRoomAdvert: defineRoute<{ listingId: string; from: string }>({
    pathname: '/preview-roomadvert/[listingId]/[from]',
    nlPathname: '/voorbeeldweergave/[listingId]/[from]',
    isLandlordRoute: true,
  }),

  EditAdvert: defineRoute<{ roomId: string }>({
    pathname: '/edit-advert',
    nlPathname: '/wijzig-advertentie',
    isLandlordRoute: true,
  }),

  EmailUnsubscribe: defineRoute<{ emailType: string }>({
    pathname: '/email-unsubscribe',
    nlPathname: '/email-uitschrijven',
  }),

  ListingsSearchSitemapByListingTypeId: defineRoute<{
    cityOrListingTypeId: string; // for this route [cityOrListingTypeId] means `listingTypeId`
  }>({
    pathname: '/sitemap/[cityOrListingTypeId]',
  }),

  ListingsSearchSitemapByCityId: defineRoute<{
    cityOrListingTypeId: string; // for this route [cityOrListingTypeId] means `city`
    cityId: string;
  }>({
    pathname: '/sitemap/[cityOrListingTypeId]/[cityId]',
  }),

  TenantPublicProfile,

  // @placeholder:route <= used by kamernet-page code generator to inject route definition
});

/** routes that are publicly accessible but changes visually when a user is authenticated */
export const PUBLIC_ROUTES: readonly string[] = Object.freeze([
  ROUTES.Home.pathname,
  ROUTES.Search.pathname,
  ROUTES.RentOutRoom.pathname,
  ROUTES.HowDoesItWorkTenant.pathname,
  ROUTES.HowDoesItWorkLandlord.pathname,
  ROUTES.TermsAndConditions.pathname,
  ROUTES.PrivacyPolicy.pathname,
  ROUTES.SafetyLandlord.pathname,
  ROUTES.SafetyTenant.pathname,
  ROUTES.AboutUs.pathname,
  ROUTES.CookiePolicy.pathname,
  ROUTES.RentOk.pathname,
  ROUTES.ListingSearchCity.pathname,
  ROUTES.ListingSearchStreet.pathname,
  ROUTES.ListingDetails.pathname,
  ROUTES.PaymentStatus.pathname,
  ROUTES.PaymentReminder.pathname,
  ROUTES.LandlordProfile.pathname,
  ROUTES.EmailUnsubscribe.pathname,
  ROUTES.ListingsSearchSitemapByListingTypeId.pathname,
  ROUTES.ListingsSearchSitemapByCityId.pathname,
  ROUTES.TenantPublicProfile.pathname,
  ROUTES.PremiumListing.pathname,
  // ...
]);

export const ROUTE_TO_IMPLEMENTATION_MAPPING = Object.freeze(
  Object.fromEntries(
    Object.values(ROUTES).map(v => [v.pathnameByLocale[Locale.nl], v.pathname]),
  ),
) as Record<string, string>;

export function getRouteByPathname(pathname: string) {
  return (
    Object.values(ROUTES).find(route => route.pathname === pathname) || null
  );
}
